////mobile menu
$(document).mouseup(function(e)
{
    var container = $(".sidenav");
    var test = $(".dropdown-toggle");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0 && $("body").hasClass("mobile-menu-active"))
    {
        openNav();
    }

    $(".navbar-toggler").click(function(e){
        openNav();
    });
});

$(document).ready(function () {

    var $menu = '<div class="sidenav">\n' +
        '          <div class="sidenav-panels">\n' +
        '            <div class="sidenav-panel" id="panel-menu">\n' +
        '               <ul>' + $( "#nav-main" ).html() + '</ul>\n' +
        '            </div>\n' +
        '          </div>\n' +
        '        </div>';

    $($menu).appendTo( "#page-wrapper" );

    $("#panel-menu > ul > li > a").click(function(e){
        $("body").removeClass("mobile-menu-active");
        $(".navbar-toggler").toggleClass("navbar-toggler--open");
    });

    $("#panel-menu > ul > li.has-subitems").each(function(index,value){
        $(this).find('.dropdown-toggle').click(function(e){
            $("#panel-"+index).addClass("sidenav-panel_open");
        });
        $(".sidenav-panel-sub").find("ul li.has-subitems").each(function(index1,value2){
            $(value2).find('.dropdown-toggle').click(function(e){
                $("#panel-sub-"+index1).addClass("sidenav-panel_open");
            });
        });
    });

    $(".backbtn").click(function(){
        $(this).parents(".sidenav-panel").toggleClass("sidenav-panel_open");
    });
    //sub
    $("#panel-menu > ul > li.has-subitems").each(function(index,value){

        var itemName = $(this).find("> a").text();
        var subItems = $(value).find(".submenu").html();

        var panel = '<div id="panel-'+ index +'" class="sidenav-panel sidenav-panel-sub"><a href="javascript:void(0)" class="backbtn">Terug</a><div class="sidenav-panel-label">'+ itemName +'</div>'+ subItems +'</div>';
        $(".sidenav-panels").append(panel);

        $(this).find('.dropdown-toggle').click(function(e){
            $("#panel-"+index).addClass("sidenav-panel_open");
        });

        //subsub
        $(".sidenav-panel-sub").find("ul li.has-subitems").each(function(index1,value2){
            var subItemName = $(this).find("> a").text();
            var subsubItems = $(value2).find(".submenu").html();

            var panel2 = '<div id="panel-sub-'+ index1 +'" class="sidenav-panel sidenav-panel-sub-sub"><a href="javascript:void(0)" class="backbtn">Terug</a><div class="sidenav-panel-label">'+ subItemName +'</div>'+ subsubItems +'</div>';
            $(".sidenav-panels").append(panel2);

            $(value2).find('.dropdown-toggle').click(function(e){
                $("#panel-sub-"+index1).addClass("sidenav-panel_open");
            });
        });
    });

    $(".backbtn").click(function(){

        $(this).parents(".sidenav-panel").toggleClass("sidenav-panel_open");
    });


    $(".navbar-toggler").click(function(){
        $("body").toggleClass("mobile-menu-active");
        $(".navbar-toggler").toggleClass("navbar-toggler--open");
    });

    $(".closebtn").click(function(){
        $("body").toggleClass("mobile-menu-active");
        $(".navbar-toggler").toggleClass("navbar-toggler--open");
    });

    function openNav() {

    }

    function closeNav() {
        $("body").toggleClass("mobile-menu-active");
        $(".navbar-toggler").toggleClass("navbar-toggler--open");
        // document.getElementById("page-wrapper").classList.remove('mobile-menu-active');
    }

    function goBack() {
        $(".sidenav-panel").removeClass("sidenav-panel_open");
    }
});

//// sticky header
var navbar = $("#navbar");
var header = $(".c-header");
var topbar = $(".c-topbar");

navbar.removeClass("sticky");

$(window).scroll(function () {

    if (navbar !== undefined && navbar !== null) {
        var offsetTopPosition = navbar.offsetTop;

        // console.log(topbar.height())
        if (topbar.height()) {
            if (window.pageYOffset >= topbar.height()) {

                // navbar.addClass("sticky")
                header.addClass("header--fixed")
            } else {
                // navbar.removeClass("sticky");
                header.removeClass("header--fixed")
            }
        } else {
            if (window.pageYOffset >= 0) {

                // navbar.addClass("sticky")
                header.addClass("header--fixed")
            } else {
                // navbar.removeClass("sticky");
                header.removeClass("header--fixed")
            }
        }
    }
});
