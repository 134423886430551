$(".c-slider-card").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 26,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: true,
        autoHeight: false,
        responsive: {
            460: {
                items: 2
            },
            768: {
                items: 3
            },
            1200: {
                items: 4
            }
        },
    });
});
