$(".c-slider-employees").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 0,
        nav: false,
        loop: false,
        controls: true,
        mouseDrag: true,
        autoHeight: false,
        responsive: {
            460: {
                items: 2
            },
            1460: {
                items: 3
            }
        },
    });
});

function ifMobile(x) {
    if (x.matches) {
        equalHeightEmployeeSlider(false)
        $(window).resize(function () {
            equalHeightEmployeeSlider(false)
        });
    } else {
        equalHeightEmployeeSlider(true)
        $(window).resize(function () {
            equalHeightEmployeeSlider(true)
        });
    }
}

function equalHeightEmployeeSlider(state) {
    if(state === true) {
        var containerHeight = $(".o-employees .c-banner").height();
        $(".employee__image .c-image img").height(containerHeight);
    } else {
        $(".employee__image .c-image img").height('auto');
    }
}
var x = window.matchMedia("(max-width: 991px)")
ifMobile(x) // Call listener function at run time
x.addListener(ifMobile) // Attach listener function on state changes
